import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";

import { motion } from "framer-motion";
import { useCountUp } from "react-countup";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

import featProjectsData from "../../data/feat-projects.json";

import SingleProjectList from "../porfoliopage/SingleProjectList";
import { BaselineAnimText } from "../common/AnimatedText";
import LinkArrow from "../../svg/LinkArrow";
import TopRatedUpwork from "../../svg/TopRatedUpwork";

const Intro = () => {
  // const projectContainer = document.getElementById("projectSlider");
  // const projectScrollWidth=1;
  const [project1Hovered, setProject1Hovered] = useState(false);
  const [project2Hovered, setProject2Hovered] = useState(false);
  const [project3Hovered, setProject3Hovered] = useState(false);
  const [project4Hovered, setProject4Hovered] = useState(false);

  let experienceCountRef = useRef(null);
  let projectsCountRef = useRef(null);
  let satisfactionCountRef = useRef(null);

  useCountUp({ ref: "experienceCountRef", start: 0, end: 3, delay: 3 });

  return (
    <section className="w-full min-h-screen center">
      <div className="main-container h-full pb-12 pt-32 flex flex-col gap-y-14 lg:gap-y-16">
        {/* text part ---------------------------------------------------------------*/}
        <div className="flex flex-col lg:flex-row lg:items-end justify-between gap-y-12">
          <div className="lg:w-1/2 theme-p2 order-2 lg:order-1">
            <Link to="https://www.upwork.com/freelancers/~017bba686d6c287b6e" target="_blank">
              <div className="top-rated-container">
                <div className="w-6">
                  <TopRatedUpwork />
                </div>
                <div className="theme-p2 link-hover">Top Rated on Upwork</div>
              </div>
            </Link>

            <div className="number_counter-container">
              <div className="number_counter">
                <div className="theme-h1">
                  <CountUp end={3.6}>
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                  yrs
                  {/* <CountUp end={3} /> yrs */}
                </div>
                <div className="desc">
                  <BaselineAnimText text="Experience" />
                  <span className="lg:hidden">
                    <BaselineAnimText text="in Industry" />
                  </span>
                </div>
              </div>
              <div className="number_counter">
                <div className="theme-h1">
                  <CountUp end={34}>
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                  +
                </div>
                <div className="desc">
                  <BaselineAnimText text="Projects" />
                  <span className="lg:hidden">
                    <BaselineAnimText text="Completed" />
                  </span>
                </div>
              </div>
              <div className="number_counter">
                <div className="theme-h1">
                  <CountUp end={100}>
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                  %
                </div>
                <div className="desc">
                  <span className="hidden lg:flex">
                    <BaselineAnimText text="Client Satisfaction" />
                  </span>
                  <span className="lg:hidden">
                    <BaselineAnimText text="Client" />
                    <BaselineAnimText text="Satisfaction" />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:w-1/2 text-right theme-lgtext flex flex-col items-center lg:items-end order-1 lg:order-2 font-semibold">
            {/* Erika ------------------------------------------------------------------------*/}
            <h1>
              <BaselineAnimText text="Erika" paddingy="0.75rem" />
            </h1>

            <h2 className="-mt-[1rem] lg:-mt-[2rem]">
              {/* Gurung */}

              <BaselineAnimText text="Gurung" paddingy="0.75rem" />
            </h2>
          </div>
        </div>

        <div>
          <div className="text-2xl font-semibold pb-3 text-center lg:text-left flex justify-center lg:justify-between">
            {/* Featured Projects: */}
            <BaselineAnimText text="Featured Projects:" />
            <Link
              to="/projects"
              className="text-right hidden lg:flex link-hover text-theme-gray dark:text-[#aaaaaa]"
            >
              View More
            </Link>
          </div>

          {/* projects mbl version */}
          <div className="lg:hidden projects_list-wrapper !py-2">
            <SingleProjectList
              imgSrc={featProjectsData[0].imgSrc}
              link={featProjectsData[0].link}
              numbering="01"
              title={featProjectsData[0].name}
              categories={featProjectsData[0].category}
            />
            <SingleProjectList
              imgSrc={featProjectsData[1].imgSrc}
              link={featProjectsData[1].link}
              numbering="02"
              title={featProjectsData[1].name}
              categories={featProjectsData[1].category}
            />
            <SingleProjectList
              imgSrc={featProjectsData[2].imgSrc}
              link={featProjectsData[2].link}
              numbering="03"
              title={featProjectsData[2].name}
              categories={featProjectsData[2].category}
            />
            <SingleProjectList
              imgSrc={featProjectsData[3].imgSrc}
              link={featProjectsData[3].link}
              numbering="04"
              title={featProjectsData[3].name}
              categories={featProjectsData[3].category}
            />
          </div>
          <div className="center lg:hidden mt-2">
            <Link to="/projects" className="linked-btn w-max">
              View More
            </Link>
          </div>
          {/* projects slider -----------------------------------------------*/}
          <div className="py-2 overflow-hidden">
            <motion.div
              className="marquee-container gap-3 n hidden lg:flex"
              initial={{ y: "30%" }}
              animate={{ y: 0 }}
              transition={{ ease: [0.455, 0.03, 0.515, 0.955], duration: 0.3 }}
            >
              <div className="marquee " id="projectSlider">
                {/* 1st part */}
                <div
                  className="group"
                  onMouseEnter={() => setProject1Hovered(true)}
                  onMouseLeave={() => setProject1Hovered(false)}
                >
                  <SingleMarquee
                    imgSrc={featProjectsData[0].imgSrc}
                    imgAlt={featProjectsData[0].imgAlt}
                    name={featProjectsData[0].name}
                    link={featProjectsData[0].link}
                  />
                </div>
                <div
                  className="group"
                  onMouseEnter={() => setProject2Hovered(true)}
                  onMouseLeave={() => setProject2Hovered(false)}
                >
                  <SingleMarquee
                    imgSrc={featProjectsData[1].imgSrc}
                    imgAlt={featProjectsData[1].imgAlt}
                    name={featProjectsData[1].name}
                    link={featProjectsData[1].link}
                  />
                </div>
                <div
                  className="group"
                  onMouseEnter={() => setProject3Hovered(true)}
                  onMouseLeave={() => setProject3Hovered(false)}
                >
                  <SingleMarquee
                    imgSrc={featProjectsData[2].imgSrc}
                    imgAlt={featProjectsData[2].imgAlt}
                    name={featProjectsData[2].name}
                    link={featProjectsData[2].link}
                  />
                </div>
                <div
                  className="group"
                  onMouseEnter={() => setProject4Hovered(true)}
                  onMouseLeave={() => setProject4Hovered(false)}
                >
                  <SingleMarquee
                    imgSrc={featProjectsData[3].imgSrc}
                    imgAlt={featProjectsData[3].imgAlt}
                    name={featProjectsData[3].name}
                    link={featProjectsData[3].link}
                  />
                </div>

                {/* 2nd part */}
                <div
                  className="group hidden lg:flex"
                  onMouseEnter={() => setProject1Hovered(true)}
                  onMouseLeave={() => setProject1Hovered(false)}
                >
                  <SingleMarquee
                    imgSrc={featProjectsData[0].imgSrc}
                    imgAlt={featProjectsData[0].imgAlt}
                    name={featProjectsData[0].name}
                    link={featProjectsData[0].link}
                  />
                </div>
                <div
                  className="group hidden lg:flex"
                  onMouseEnter={() => setProject2Hovered(true)}
                  onMouseLeave={() => setProject2Hovered(false)}
                >
                  <SingleMarquee
                    imgSrc={featProjectsData[1].imgSrc}
                    imgAlt={featProjectsData[1].imgAlt}
                    name={featProjectsData[1].name}
                    link={featProjectsData[1].link}
                  />
                </div>
                <div
                  className="group hidden lg:flex"
                  onMouseEnter={() => setProject3Hovered(true)}
                  onMouseLeave={() => setProject3Hovered(false)}
                >
                  <SingleMarquee
                    imgSrc={featProjectsData[2].imgSrc}
                    imgAlt={featProjectsData[2].imgAlt}
                    name={featProjectsData[2].name}
                    link={featProjectsData[2].link}
                  />
                </div>
                <div
                  className="group hidden lg:flex"
                  onMouseEnter={() => setProject4Hovered(true)}
                  onMouseLeave={() => setProject4Hovered(false)}
                >
                  <SingleMarquee
                    imgSrc={featProjectsData[3].imgSrc}
                    imgAlt={featProjectsData[3].imgAlt}
                    name={featProjectsData[3].name}
                    link={featProjectsData[3].link}
                  />
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
};

const SingleMarquee = ({ link, name, imgSrc, imgAlt, setProjectHovered }) => {
  return (
    <Link
      to={link}
      onClick={() => {
        window.scroll(0, 0);
      }}
    >
      <div className="marquee-element">
        <img src={imgSrc} alt={imgAlt} className="min-w-full" />
        <div className="marquee-element_text">{name}</div>
        {/* <div className="marquee-element_text hidden lg:flex">View Case</div> */}
        <div className="marquee-element_arrow">
          <LinkArrow />
        </div>
      </div>
    </Link>
  );
};

export default Intro;
