import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../svg/Logo";
import { AnimatePresence, motion } from "framer-motion";
import DarkLightToggle from "./DarkLightToggle";
import MobileMenu from "./MobileMenu";
import navLinksData from "../../data/nav-links-data.json";

const Header = () => {
  const [mobileMenuActivated, setMobileMenuActivated] = useState(false);
  return (
    <div className="header glass-effect z-30 fixed top-0 left-0 w-screen bg-theme-white bg-opacity-30 text-theme-black dark:bg-theme-black dark:bg-opacity-30 dark:text-theme-white">
      <div className="relative z-20 main-container flex justify-between items-center h-16 lg:h-24">
        <Link
          to="/"
          onClick={() => {
            window.scroll(0, 0);
          }}
          className="logo cursor-pointer w-10 lg:w-16"
        >
          <Logo />
        </Link>
        <div className="nav-links absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] h-full theme-p2 hidden lg:flex gap-x-6 lg:gap-x-12 items-center">
          {navLinksData.map((item, index) => (
            <Link
              key={item.index}
              to={item.link}
              onClick={() => {
                window.scroll(0, 0);
              }}
              className="link-hover"
            >
              {item.name}
            </Link>
          ))}
          <Link to="https://www.upwork.com/freelancers/~017bba686d6c287b6e" target="_blank"><div className="link-hover">Upwork Profile</div></Link>
        </div>
        <div className="flex gap-x-5 lg:gap-x-12">
          <DarkLightToggle />
          {/* hamburger icon */}
          <button
            className="relative flex lg:hidden flex-col justify-center w-6 h-[1.6rem]"
            onClick={() => setMobileMenuActivated(!mobileMenuActivated)}
          >
            <div
              className={`absolute top-[15%] w-full h-[2.5px] rounded-sm bg-theme-black dark:bg-theme-white ${
                mobileMenuActivated
                  ? "rotate-45 translate-y-[0.5rem] w-[110%] duration-300"
                  : "rotate-0 duration-300"
              }`}
            ></div>
            <div
              className={`absolute top-[50%] -translate-y-[50%] w-full h-[2.5px] rounded-sm bg-theme-black dark:bg-theme-white ${
                mobileMenuActivated
                  ? "opacity-0 duration-300"
                  : "opacity-1 duration-300"
              }`}
            ></div>
            <div
              className={`absolute bottom-[15%] w-full h-[2.5px] rounded-sm  bg-theme-black dark:bg-theme-white ${
                mobileMenuActivated
                  ? "-rotate-45 -translate-y-[0.5rem] w-[110%] duration-300"
                  : "rotate-0 duration-300"
              }`}
            ></div>
          </button>
          {/* <button
            className="relative flex lg:hidden flex-col justify-center w-6 min-h-[32px]"
            onClick={() => setMobileMenuActivated(!mobileMenuActivated)}
          >
            <div
              className={`absolute top-[30%] w-full h-[3px] rounded-sm bg-theme-black dark:bg-theme-white ${
                mobileMenuActivated
                  ? "rotate-45 translate-y-[0.3rem] duration-300"
                  : "rotate-0 duration-300"
              }`}
            ></div>
            <div
              className={`absolute bottom-[30%] w-full h-[3px] rounded-sm  bg-theme-black dark:bg-theme-white ${
                mobileMenuActivated
                  ? "-rotate-45 -translate-y-[0.3rem] duration-300"
                  : "rotate-0 duration-300"
              }`}
            ></div>
          </button> */}
        </div>
      </div>

      <AnimatePresence>
        {mobileMenuActivated && (
          <motion.div
            initial={{ x: "100%" }}
            animate={{ x: 0 }}
            exit={{ x: "100%" }}
            transition={{ duration: 0.6 }}
            className="absolute z-10 top-0 left-0"
          >
            <MobileMenu
              mobileMenuActivated={mobileMenuActivated}
              setMobileMenuActivated={setMobileMenuActivated}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
export default Header;
