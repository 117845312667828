import React, { useState } from "react";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import SocialInsta from "../../svg/SocialInsta";
import SocialEmail from "../../svg/SocialEmail";
import SocialUpwork from "../../svg/SocialUpwork";
import SocialLinkedIn from "../../svg/SocialLinkedIn";

const Footer = () => {
  return (
    <section className="relative w-full bg-theme-gray text-theme-black dark:bg-theme-black dark:text-theme-white bg-opacity-10 dark:bg-opacity-[0.98] overflow-hidden">
      <div className="main-container relative z-10">
        <div className="w-full flex flex-col justify-center items-center">
          <div className="py-20 lg:py-32 lg:h-[95vh] center flex-col">
            <div className="theme-p1">Want to create amazing stuff?</div>
            <SayHello link="https://www.upwork.com/freelancers/~017bba686d6c287b6e" />
            {/* <SayHello link="mailto:hello@erikagurung.com" /> */}
          </div>
          <div className="relative flex flex-col items-center lg:flex-row w-full lg:justify-between theme-p2 mb-5">
            <div className="order-2 lg:order-1 lg:mb-5">
              © 2024 All Rights Reserved.
            </div>
            {/* <div className="social-icons_container lg:absolute lg:top-[50%] lg:left-[50%] lg:-translate-x-[50%] lg:-translate-y-[50%]  order-1 lg:order-2 gap-x-5 flex pb-5">
              <SocialUpwork />
              <SocialInsta />
              <SocialEmail />
              <SocialLinkedIn />
            </div> */}
            <div
              className="back-to-top hidden lg:flex order-3 cursor-pointer items-center link-hover lg:mb-5"
              onClick={() => {
                window.scroll(0, 0);
              }}
            >
              <span>Back To Top</span>&nbsp;&nbsp;
              <motion.div
                initial={{ y: -10 }}
                animate={{ y: 0 }}
                transition={{
                  duration: 0.5,
                  repeat: Infinity,
                  repeatType: "reverse",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="none"
                  viewBox="0 0 30 30"
                >
                  <path
                    className="fill-theme-black dark:fill-theme-white"
                    d="M5 15L15 5l10 10-1.75 1.781-7-7V25h-2.5V9.781l-7 7L5 15z"
                  ></path>
                </svg>
              </motion.div>
            </div>
          </div>
          {/* <div className="text-center theme-p3 text-theme-gray">
            *If you're visiting this site from any freelancing sites, please use
            their channels to contact me.
          </div> */}
        </div>
      </div>
      <div className="absolute top-0 left-0 h-1/2 aspect-square bg-theme-blue blur-3xl opacity-[0.06] rounded-full"></div>
      <div className="absolute bottom-[10%] right-0 h-1/3 aspect-square bg-theme-pink blur-3xl opacity-[0.06] rounded-full"></div>
    </section>
  );
};

const SayHello = ({ link, text }) => {
  const [itemHovered, setItemHovered] = useState(false);
  return (
    <Link
      to={link}
      onClick={() => {
        window.scroll(0, 0);
      }}
    >
      <div
        className="relative h-full overflow-hidden"
        onMouseEnter={() => setItemHovered(true)}
        onMouseLeave={() => setItemHovered(false)}
      >
        <div className="theme-lgtext font-semibold text-center">
          Say H<span className="theme-italic">e</span>llo
        </div>
        {/* {text} */}
        <AnimatePresence>
          {itemHovered && (
            <motion.div
              initial={{ x: "-100%" }}
              animate={{ x: 0 }}
              exit={{ x: "100%" }}
              transition={{ ease: "easeOut" }}
              className="absolute bottom-0 left-0 w-full h-[6px] bg-theme-black dark:bg-theme-white"
            ></motion.div>
          )}
        </AnimatePresence>
      </div>
    </Link>
  );
};

export default Footer;
