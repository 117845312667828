import React, { useEffect, useRef, useState } from "react";
import gsap from "gsap/dist/gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { BaselineAnimText } from "../common/AnimatedText";

import DevelopmentPink from "../../svg/DevelopmentPink";
import CodeLeftBracket from "../../svg/CodeLeftBracket";
import CodeRightBracket from "../../svg/CodeRightBracket";
import Javascript from "../../svg/skillIcons/Javascript";
import HTML from "../../svg/skillIcons/HTML";
import ReactLogo from "../../svg/skillIcons/ReactLogo";
import TailwindLogo from "../../svg/skillIcons/TailwindLogo";
import CSSLogo from "../../svg/skillIcons/CSSLogo";
import NextJsLogo from "../../svg/skillIcons/NextJsLogo";

function Development() {
  // coding brackets refs
  let codingBracketsContainerRef = useRef(null);
  let dashRef = useRef(null);
  let codeLeftBracketRef = useRef(null);
  let codeRightBracketRef = useRef(null);

  // coding brackets anim
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    var tl = gsap.timeline();

    let codingBracketsAnim1 = gsap.to(codingBracketsContainerRef, {
      scrollTrigger: {
        trigger: codingBracketsContainerRef,
        start: "top 50%",
        end: "+=150",
        pin: true,
        pinSpacing: true,
      },
    });
    let codingBracketsAnim2 = gsap.to(dashRef, {
      skewX: -12,
      scrollTrigger: {
        trigger: dashRef,
        start: "top 50%",
        end: "+=150",
        scrub: 1,
      },
    });
    let codingBracketsAnim3 = gsap.fromTo(
      codeLeftBracketRef,
      { opacity: 0, x: 100 },
      {
        opacity: 1,
        x: 0,
        scrollTrigger: {
          trigger: codeLeftBracketRef,
          start: "top 50%",
          end: "+=150",
          scrub: 1,
        },
      }
    );
    let codingBracketsAnim4 = gsap.fromTo(
      codeRightBracketRef,
      { opacity: 0, x: -100 },
      {
        opacity: 1,
        x: 0,
        scrollTrigger: {
          trigger: dashRef,
          start: "top 50%",
          end: "+=150",
          scrub: 1,
        },
      }
    );

    return () => {
      codingBracketsAnim1.kill();
      codingBracketsAnim2.kill();
      codingBracketsAnim3.kill();
      codingBracketsAnim4.kill();
    };
  });

  return (
    <div className="min-h-screen center overflow-hidden">
      <div className="main-container flex-col center py-20 lg:pt-20 lg:pb-32 !overflow-visible">
        <div className="i-can-help theme-h1 text-center font-semibold">
          <BaselineAnimText text="I can help you with..." />
        </div>

        {/* the animated svg------------------------------------------------------- */}
        <div
          className="pb-8 lg:pb-20 flex gap-5 lg:gap-10 justify-center items-center relative"
          ref={(el) => (codingBracketsContainerRef = el)}
        >
          <div className="w-28 lg:w-48" ref={(el) => (codeLeftBracketRef = el)}>
            <CodeLeftBracket />
          </div>
          <div className="w-5 lg:w-8" ref={(el) => (dashRef = el)}>
            <DevelopmentPink />
          </div>
          <div
            className="w-28 lg:w-48"
            ref={(el) => (codeRightBracketRef = el)}
          >
            <CodeRightBracket />
          </div>
          <div className="absolute top-0 left-0 h-1/2 aspect-square bg-theme-blue blur-3xl opacity-[0.06] dark:opacity-[0.15] rounded-full"></div>
          <div className="absolute bottom-[10%] right-0 h-1/3 aspect-square bg-theme-pink blur-3xl opacity-[0.06] dark:opacity-[0.15] rounded-full"></div>
        </div>

        {/* text section ------------------------------------------------------------ */}
        <div className="flex flex-col lg:flex-row w-full justify-between gap-12 lg:gap-20 lg:pt-4">
          <div className="leftpart lg:w-1/2">
            <h2 className="theme-h1 font-semibold pb-5 lg:pb-10">
              <BaselineAnimText text="01. Development" />
            </h2>
            <p className="theme-p1 pb-8 lg:pb-16">
              <BaselineAnimText text="I specialize in creating responsive and user-friendly websites that not only look great but also perform seamlessly. From WordPress sites to complex coded sites, I have the skills to bring your ideas to life." />
            </p>
            <Link to="https://www.upwork.com/freelancers/~017bba686d6c287b6e">
              <div className="linked-btn">Contact Me</div>
            </Link>
          </div>
          <div className="rightpart lg:w-1/2 grid grid-cols-3 gap-5">
            <motion.div
              className="language-logo group"
              initial={{ y: "80%" }}
              whileInView={{ y: 0 }}
              transition={{ ease: [0.455, 0.03, 0.515, 0.955] }}
            >
              <HTML />
              <span className=" scale-0 group-hover:scale-100 font-bold transition">
                HTML
              </span>
            </motion.div>
            <motion.div
              className="language-logo group"
              initial={{ y: "80%" }}
              whileInView={{ y: 0 }}
              transition={{ ease: [0.455, 0.03, 0.515, 0.955] }}
            >
              <CSSLogo />
              <span className=" scale-0 group-hover:scale-100 font-bold transition">
                CSS
              </span>
            </motion.div>
            <motion.div
              className="language-logo group"
              initial={{ y: "80%" }}
              whileInView={{ y: 0 }}
              transition={{ ease: [0.455, 0.03, 0.515, 0.955] }}
            >
              <TailwindLogo />
              <span className=" scale-0 group-hover:scale-100 font-bold transition">
                Tailwind
              </span>
            </motion.div>
            <motion.div
              className="language-logo group"
              initial={{ y: "80%" }}
              whileInView={{ y: 0 }}
              transition={{ ease: [0.455, 0.03, 0.515, 0.955] }}
            >
              <Javascript />
              <span className=" scale-0 group-hover:scale-100 font-bold transition">
                Javascript
              </span>
            </motion.div>
            <motion.div
              className="language-logo group"
              initial={{ y: "80%" }}
              whileInView={{ y: 0 }}
              transition={{ ease: [0.455, 0.03, 0.515, 0.955] }}
            >
              <ReactLogo />
              <span className=" scale-0 group-hover:scale-100 font-bold transition">
                React
              </span>
            </motion.div>
            <motion.div
              className="language-logo group"
              initial={{ y: "80%" }}
              whileInView={{ y: 0 }}
              transition={{ ease: [0.455, 0.03, 0.515, 0.955] }}
            >
              <NextJsLogo />
              <span className=" scale-0 group-hover:scale-100 font-bold transition">
                Next.js
              </span>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Development;
