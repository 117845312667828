import React from 'react'

const TopRatedUpwork = () => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
     className="w-full"
    fill="none"
    ariaHidden="true"
    viewBox="0 0 28 28"
  >
    <path
      fill="#1F57C3"
      d="M12 1.155a4 4 0 0 1 4 0l8.124 4.69a4 4 0 0 1 2 3.464v9.382a4 4 0 0 1-2 3.464L16 26.845a4 4 0 0 1-4 0l-8.124-4.69a4 4 0 0 1-2-3.464V9.309a4 4 0 0 1 2-3.464z"
    ></path>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="m14.761 7.542 1.188 3.201 3.285.184a.8.8 0 0 1 .448.173c.13.104.226.247.277.41a.9.9 0 0 1 .01.504.86.86 0 0 1-.261.422L17.15 14.6l.854 3.328a.9.9 0 0 1-.025.507.86.86 0 0 1-.291.404.785.785 0 0 1-.919.02L14 16.984l-2.764 1.862a.78.78 0 0 1-.916-.012.86.86 0 0 1-.294-.4.9.9 0 0 1-.031-.505l.847-3.314-2.55-2.18a.86.86 0 0 1-.26-.421.9.9 0 0 1 .01-.504.85.85 0 0 1 .276-.41.8.8 0 0 1 .448-.173l3.285-.184 1.188-3.201a.86.86 0 0 1 .302-.394.79.79 0 0 1 .918 0 .86.86 0 0 1 .302.394"
      clipRule="evenodd"
    ></path>
  </svg>
  )
}

export default TopRatedUpwork